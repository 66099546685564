import React from 'react';

import GoogleMapReact from 'google-map-react';
import './index.css';
import MapPin from 'src/assets/icons/map_pin.svg';
import googleRoute from 'src/assets/icons/google_route.png';
// THIS IS FIX FOR STOP GOOGLE CHANGING FONTS!
var head = document.getElementsByTagName('head')[0];

var insertBefore = head.insertBefore;

// THIS IS FIX FOR STOP GOOGLE CHANGING FONTS!
head.insertBefore = function (newElement, referenceElement) {
  if (
    newElement.href &&
    newElement.href.indexOf(
      'https://fonts.googleapis.com/css?family=Roboto',
    ) === 0
  ) {
    return;
  }
  // THIS IS FIX FOR STOP GOOGLE CHANGING FONTS!
  insertBefore.call(head, newElement, referenceElement);
};
// THIS IS FIX FOR STOP GOOGLE CHANGING FONTS!

const OpenMap = () => (
  <div>
    <a
      href="https://www.google.com/maps/place/%D0%94%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F+%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D0%B0/@45.054052,39.9330624,8.8z/data=!4m5!3m4!1s0x0:0x667e72f01d0496b6!8m2!3d45.2251052!4d39.1971512"
      className="google-map__route-button--map"
    >
      <img src={googleRoute} alt="marshrut" className="route-button__icon" />
      <h3 className="route-button__title">Проложить маршрут</h3>
    </a>
  </div>
);

export default () => {
  const defaultProps = {
    center: {
      lat: 45.206563,
      lng: 39.2240004,
    },
    zoom: 8,
  };

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 45.2251052, lng: 39.1971512 },
      map,
      label: {
        text: 'Динская Мельница',
        color: 'black',
        fontWeight: '600',
        // fontFamily: 'Roboto',
        fontSize: '24px',
      },
    });
    map.addListener('center_changed', () => {
      // 3 seconds after the center of the map has changed, pan back to the
      // marker.
      // window.setTimeout(() => {
      //   map.panTo(marker.getPosition());
      // }, 3000);
    });
    marker.addListener('click', () => {
      map.setZoom(10);
      map.setCenter(marker.getPosition());
    });

    // new maps.event.trigger(marker, 'click');
    return marker;
  };
  return (
    <div className="google-map__container">
      <h4 className="google-map__title"> Мы на карте </h4>
      <div className="google-map__adress">
        <MapPin className="adress__icon" />
        <h5 className="adress__title"> Адрес:</h5>
        <a
          className="adress__link"
          href="https://www.google.com/maps/place/%D0%94%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F+%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D0%B0/@45.054052,39.9330624,8.8z/data=!4m5!3m4!1s0x0:0x667e72f01d0496b6!8m2!3d45.2251052!4d39.1971512"
        >
          <p className="adress__info">
            Краснодарский край, <br />
            ст. Динская, ул.Крайняя 12а
          </p>
        </a>
      </div>
      <a
        href="https://www.google.com/maps/place/%D0%94%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F+%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D0%B0/@45.054052,39.9330624,8.8z/data=!4m5!3m4!1s0x0:0x667e72f01d0496b6!8m2!3d45.2251052!4d39.1971512"
        className="google-map__route-button"
      >
        <img src={googleRoute} alt="marshrut" className="route-button__icon" />
        <h3 className="route-button__title">Проложить маршрут</h3>
      </a>

      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GOOGLE_API_KEY,
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
          <OpenMap lat={45.216184} lng={39.195599} />
        </GoogleMapReact>
      </div>
    </div>
  );
};
