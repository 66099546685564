import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import { AppContainer } from 'react-hot-loader';
import { PersistGate } from 'redux-persist/integration/react';

import './index.css';
import App from './App';
import { store, persistor } from './store';

require('react-hot-loader/patch');
let history = createBrowserHistory();

const renderApp = (Main = App) =>
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </AppContainer>,

    document.getElementById('root'),
  );

renderApp();

if (module.hot) {
  module.hot.accept('./App', () => renderApp(require('./App').default));
}
