import { compose } from 'redux';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import OrderForm from './OrderForm';
import { sendOrder } from 'src/support/redux/actions';
// const validationSchema = Yup.object().shape(
//   {
//     Name: Yup.string().required('Введите ваше имя'),
//     phone: Yup.string()
//       .matches(phoneRegExp, 'Номер не валиден')
//       .when('email', {
//         is: email => !email,
//         then: Yup.string().required('Необходим ваш телефон'),
//       }),
//     email: Yup.string()
//       .email('Email не валиден')
//       .when('phone', {
//         is: phone => !phone,
//         then: Yup.string().required('Необходим ваш email'),
//       }),
//   },
//   ['phone', 'email'],
// );
const phoneRegExp =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Обязательное поле для ввода'),

  phoneOrEmail: Yup.string().required('Обязательное поле для ввода'),
});

export default compose(
  connect(
    state => ({
      loading: state.support.loading,
      error: state.support.error,
      complete: state.support.complete,
      cart: state.common.cart,
    }),
    { sendOrder },
  ),
  withFormik({
    displayName: 'OrderForm',
    enableReinitialize: true,
    mapPropsToValues: ({ fullName, phoneOrEmail }) => ({
      fullName: fullName || '',
      phoneOrEmail: phoneOrEmail || '',
    }),
    validationSchema,

    handleSubmit: async (
      data,

      { resetForm, props: { sendOrder, cart } },
    ) => {
      try {
        await sendOrder(data, cart);
      } catch (error) {
        console.log(error);
      }
      resetForm();
    },
  }),
)(OrderForm);
