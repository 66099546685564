import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';

import ProductCategory from 'src/common/components/ProductCategory';

import './index.css';

export default ({ products }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (products) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [products]);
  return (
    <div className="category-list">
      <h1 id="production-list" className="category-list__title">
        Наша продукция
      </h1>
      <Spin spinning={loading} tip="Загрузка товаров...">
        {(products || []).map(category => {
          return <ProductCategory {...{ category }} key={category.id} />;
        })}
      </Spin>
    </div>
  );
};
