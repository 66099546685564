import React from 'react';

import CallusSVG from 'src/assets/icons/CallusSVG.svg';
import logo_black from 'src/assets/icons/logo_black.png';

import TelegramSVG from 'src/assets/icons/TelegramSVG.svg';

import './index.css';

export default () => {
  return (
    <div className="landing">
      <div className="landing__main-block">
        <div className="landing_main-container">
          <img src={logo_black} alt="logo" className="landing_logo" />
        </div>
      </div>

      <div className="landing__fastcontact-block">
        <a href="tel:+79385156161">
          <CallusSVG
            className="landing__fastcontact"
            style={{
              marginTop: '-100px',
            }}
          />
        </a>
      </div>
      <div className="landing__fastcontact-block">
        <a href="https://t.me/Din_Mel" target="blank">
          <TelegramSVG
            className="landing__fastcontact"
            style={{
              marginTop: '-30px',
              marginRight: `${window.innerWidth < 600 ? '50px' : '70px'}`,
            }}
          />
        </a>
      </div>
    </div>
  );
};
