import axios from 'axios';

import { resetCart } from 'src/common/redux/actions';

export const SENDING_STARTED = 'SENDING_STARTED';
export const SENDING_SUCCEED = 'SENDING_SUCCEED';
export const SENDING_FAILED = 'SENDING_FAILED';
export const RESET_STORE = 'RESET_STORE';

const sendingStarted = () => ({
  type: SENDING_STARTED,
});

const sendingSucceed = () => ({
  type: SENDING_SUCCEED,
});

const sendingFailed = error => ({
  type: SENDING_FAILED,
  payload: {
    error,
  },
});
const resetStore = () => ({
  type: RESET_STORE,
});
export function sendEmail(data) {
  return dispatch => {
    dispatch(sendingStarted());
    // console.log("current state:", getState());

    return axios
      .post(
        'https://mill-control.ru/email',
        {
          email: data.email,
          text: ` Посетитель din-mel.ru оставил свои контакты и ожидает обратной связи!
        Имя: ${data.fullName} Телефон: ${data.phone} Email: ${data.email}
        Сообщение: ${data.message}`,
        },
        { crossdomain: true },
      )
      .then(() => {
        dispatch(sendingSucceed());
      })
      .then(() => {
        setTimeout(() => {
          dispatch(resetStore());
        }, 2500);
      })
      .catch(err => {
        dispatch(sendingFailed(err.message));
      })
      .then(() => {
        setTimeout(() => {
          dispatch(resetStore());
        }, 2500);
      });
  };
}

export function sendOrder(data, cart) {
  return dispatch => {
    dispatch(sendingStarted());
    // console.log("current state:", getState());
    return axios
      .post(
        'https://mill-control.ru/sendorder',
        {
          text: `
        Новый заказ с din-mel.ru !
        Имя: ${data.fullName}
        Телефон или email: ${data.phoneOrEmail} 

          Заказанные товары: 
      ${cart.map((pr, i) => {
        return `
        ${i + 1}):
        Наименование ${pr.title}
        Размер Фасовки: ${pr.volume} кг
        Вес:${pr.weight} кг
        Цена:${pr.price} Р 
        Сумма: ${pr.price * pr.weight} Р\n`;
      })} 
`,
        },
        { crossdomain: true },
      )
      .then(() => {
        dispatch(sendingSucceed());
      })
      .then(() => {
        dispatch(resetCart());
      })
      .then(() => {
        setTimeout(() => {
          dispatch(resetStore());
        }, 2500);
      })
      .catch(err => {
        dispatch(sendingFailed(err.message));
      })
      .then(() => {
        setTimeout(() => {
          dispatch(resetStore());
        }, 2500);
      });
  };
}
