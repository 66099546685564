import createReducer from 'src/utils/createReducer';
import {
  SENDING_STARTED,
  SENDING_SUCCEED,
  SENDING_FAILED,
  RESET_STORE,
} from './actions';

const INITIAL_STATE = {
  loading: false,
  error: null,
  complete: false,
};

export default createReducer(INITIAL_STATE, {
  [SENDING_STARTED]: state => ({
    ...state,
    loading: true,
    error: null,
    complete: false,
  }),
  [SENDING_SUCCEED]: state => ({
    ...state,
    loading: false,
    complete: true,
  }),

  [SENDING_FAILED]: (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload.error,
  }),
  [RESET_STORE]: () => ({
    ...INITIAL_STATE,
  }),
});
