import React, { useEffect, useState } from 'react';

import './index.css';

export default function Snackbar({
  variant = 'success',
  text,
  duration = 2500,
}) {
  const [activeClass, setClass] = useState(`snackbar--${variant}`);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClass('');
    }, duration);
    return () => clearTimeout(timer);
  }, [duration]);

  return <div className={`snackbar ${activeClass}`}>{text}</div>;
}
