import React from 'react';

import './index.css';

export default ({ product, setVolume, selectedVolume }) => {
  return (
    <>
      <form
        className="product__volumes-form"
        name="price"
        label="price"
        onChange={e => {
          const vol = product.volumes.find(
            volume => volume.id === e.target.value,
          );
          setVolume(vol);
        }}
      >
        <h6 className="product__volumes-block__title">Объём упаковки:</h6>
        <div className="product__volumes__container">
          {product.volumes.map((volume, i, arr) => {
            return (
              <div className="product__volume" key={volume.id}>
                <input
                  id={volume.id}
                  className="product__volume__radio"
                  type="radio"
                  name="volume"
                  value={volume.id}
                  defaultChecked={volume.id === selectedVolume.id}
                />
                <label
                  key={volume.id}
                  htmlFor={volume.id}
                  className={`product__volume__radio-label ${
                    i === 0 && arr.length > 1
                      ? 'product__volume__radio-label--first'
                      : i === arr.length - 1 && arr.length > 1
                      ? 'product__volume__radio-label--last'
                      : arr.length === 1
                      ? 'product__volume__radio-label--sole'
                      : ''
                  }`}
                >
                  <h1 className="product__volume__radio-label-text">
                    {volume.volume} кг
                  </h1>
                </label>
              </div>
            );
          })}
        </div>
        <h1 className="product__type-of-bag">{selectedVolume.typeOfBag}</h1>
      </form>
    </>
  );
};
