import { connect } from 'react-redux';

import CalculateForm from './CalculateForm';

import { addToCart } from 'src/common/redux/actions';

export default connect(
  state => {
    return {
      product: state.common.product,
      selectedVolume: state.common.selectedVolume,
    };
  },
  { addToCart },
)(CalculateForm);
