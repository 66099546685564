import axios from 'axios';

import { LOAD_PRODUCT } from './types';
import { SET_VOLUME } from './types';
import { ADD_TO_CART } from './types';
import { DELETE_FROM_CART } from './types';
import { RESET_CART } from './types';
import { SET_PRICES } from './types';
import { SET_PRODUCTS } from './types';

export function loadProduct(product) {
  return {
    type: LOAD_PRODUCT,
    payload: product,
  };
}
export function setVolume(selectedVolume) {
  return {
    type: SET_VOLUME,
    payload: selectedVolume,
  };
}
export function addToCart(product) {
  return {
    type: ADD_TO_CART,
    payload: product,
  };
}
export function deleteFromCart(product) {
  return {
    type: DELETE_FROM_CART,
    payload: product,
  };
}

export function resetCart() {
  return {
    type: RESET_CART,
  };
}
const setPrices = prices => {
  return {
    type: SET_PRICES,
    prices,
  };
};
export const setProducts = () => {
  return {
    type: SET_PRODUCTS,
  };
};

export function getPrices() {
  return async (dispatch, getState) => {
    try {
      const response = await axios.get(
        'https://mill-control.ru/din-mel/prices',
      );

      dispatch(setPrices(response.data.prices));
    } catch (error) {
      console.error('Error in getPrices: ', error.message);
      return error.response.data;
    }
  };
}
