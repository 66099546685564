import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroll-component';

import CloseSVG from 'src/assets/icons/CloseSVG.svg';

import './index.css';

export default ({ cart, deleteFromCart, setPopup }) => {
  const location = useLocation();

  const [blur, setBlur] = useState('');
  function handleTotalSumm(cart) {
    var summ = [];
    var totalSumm = 0;

    cart.forEach(product => {
      summ.push(product.price * product.weight);
    });
    totalSumm = summ.reduce((sum, current) => sum + current, 0);

    return totalSumm;
  }

  return (
    <div className="popup-container">
      <div className="popup">
        <div className="popup__header">
          <h1 className="popup__title">Корзина</h1>
          <button
            onClick={() => {
              setPopup(false);
            }}
            className="popup__close-button"
          >
            <CloseSVG />
          </button>
        </div>

        <InfiniteScroll
          dataLength={cart.length} //This is important field to render the next data
          // next={fetchData}

          height="360px"
        >
          {cart.map(product => {
            return (
              <div className="popup__product-cont">
                {product.id === blur && (
                  <span className="popup__deleted"> Удалено</span>
                )}

                <div
                  className={`popup__product ${
                    product.id === blur ? 'popup__product--blur' : ''
                  }`}
                  key={product.id}
                >
                  <Link to={product.link}>
                    <img
                      src={product.photo}
                      alt="product"
                      className="popup__product-photo"
                    />
                  </Link>
                  <div className="popup__product__info-block">
                    <Link
                      onClick={() => {
                        if (location.pathname === product.link) {
                          setPopup(false);
                        }
                      }}
                      to={product.link}
                    >
                      <h1 className="popup__product-title">{product.title}</h1>
                    </Link>
                    <span className="popup__product-char">
                      Размер тары: {product.volume} кг
                    </span>
                    <span className="popup__product-char">
                      Вес: {product.weight} кг
                    </span>
                    <span className="popup__product-char">
                      Количество упаковок: {product.pieces} шт
                    </span>
                  </div>
                  <div className="popup__product__price-block">
                    <span className="popup__product-price">
                      {new Intl.NumberFormat().format(
                        product.price * product.weight,
                      )}
                      &nbsp;<span>&#8381;</span>
                    </span>

                    <button
                      onClick={e => {
                        e.preventDefault();
                        setBlur(product.id);
                        setTimeout(() => {
                          deleteFromCart({ id: product.id });
                          setBlur('');

                          if (cart.length < 2) {
                            setPopup(false);
                          }
                        }, 800);
                      }}
                      className="popup__delete"
                    >
                      Удалить
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>

        <div className="popup__footer">
          <button
            onClick={e => {
              e.preventDefault();
              setPopup(false);
            }}
            className="popup__continue"
          >
            Продолжить покупки
          </button>

          <div className="popup__order-button-container">
            <span className="popup__total">
              {new Intl.NumberFormat().format(handleTotalSumm(cart))}&nbsp;
              <span>&#8381;</span>
            </span>
            <Link to="/cart">
              <button className="popup__order-button">Оформить заказ</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
