import { connect } from 'react-redux';

import SizeSelector from './SizeSelector';
import { setVolume } from 'src/common/redux/actions';

export default connect(
  state => {
    return {
      product: state.common.product,
      selectedVolume: state.common.selectedVolume,
    };
  },
  { setVolume },
)(SizeSelector);
