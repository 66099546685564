import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HashLink as Link } from 'react-router-hash-link';

import { useHistory, useLocation } from 'react-router-dom';

import CartPopup from 'src/support/components/CartPopup';
import AddToCartSVG from 'src/assets/icons/AddToCartSVG.svg';

import './index.css';

export default ({
  selectedVolume,

  link,
  product,
  addToCart,
}) => {
  const pathname = useLocation().pathname;

  const volume = selectedVolume.volume;
  const title = `${product.category} : ${product.title}`;
  const [weight, setWeight] = useState('');
  const [error, setError] = useState('');
  const [pieces, setPieces] = useState('');
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    if (weight && +weight > 0) {
      if (weight % volume !== 0) {
        setError(true);
        setPieces('');
      } else {
        setError(false);
        setPieces(weight / volume);
      }
    } else {
      setError(false);
      setPieces('');
    }
  }, [weight, volume]);

  useEffect(() => {
    setPopup(false);
  }, [pathname]);

  return (
    <form className="calc-form">
      {popup && <CartPopup setPopup={setPopup} />}
      <div className="calc-form__input-block">
        <div>
          <h1 className="calc-form__input-title">Вес</h1>
          <div className="calc-form__input-cont">
            <input
              className="calc-form__input"
              type="input"
              onChange={e => {
                let v = e.target.value;
                if (!isNaN(+v) && +v !== 0) {
                  setWeight(v);
                } else {
                  setWeight('');
                }
              }}
              name="weight"
              value={weight}
            />
            <span className="calc-form__input-label">КГ</span>
            {error && (
              <span className="calc-form__input-error">
                Вес должен быть кратен {volume}кг !
              </span>
            )}
          </div>
        </div>

        <div>
          <h1 className="calc-form__input-title">Количество шт.</h1>
          <div className="calc-form__input-cont">
            <input
              className="calc-form__input"
              type="input"
              name="pieces"
              onChange={e => {
                let v = e.target.value;
                if (!isNaN(+v) && +v !== 0) {
                  setPieces(v);
                  setWeight(v * volume);
                } else {
                  setPieces('');
                  setWeight('');
                }
              }}
              value={pieces}
            />
            <span className="calc-form__input-label">ШТ</span>
          </div>
        </div>
      </div>
      <div className="calc-form__summ-container">
        <h1 className="calc-form__summ-title">Сумма</h1>
        <h1 className="calc-form__summ">
          {Math.floor(weight * selectedVolume.price)}
          &#8381;
        </h1>
      </div>
      {window.innerWidth < 600 ? (
        <Link
          to="/cart#cart"
          className={`${
            error
              ? 'calc-form__submit-button--disabled'
              : 'calc-form__submit-button'
          } `}
          onClick={() => {
            addToCart({
              id: uuidv4(),
              title: title,
              weight: weight,
              price: selectedVolume.price,
              volume: volume,
              pieces: pieces,
              link: link,
              photo: product.thumbnail,
            });
            setWeight('');
            setPieces('');
            setError(false);
          }}
        >
          <AddToCartSVG fill={error ? '#938E8D' : 'white'} />
          Добавить в корзину
        </Link>
      ) : (
        <button
          className="calc-form__submit-button"
          disabled={error ? true : false}
          onClick={e => {
            e.preventDefault();
            addToCart({
              id: uuidv4(),
              title: title,
              weight: weight,
              price: selectedVolume.price,
              volume: volume,
              pieces: pieces,
              link: link,
              photo: product.thumbnail,
            });
            setWeight('');
            setPieces('');
            setError(false);

            setPopup(true);
          }}
        >
          <AddToCartSVG fill={error ? '#938E8D' : 'white'} />
          Добавить в корзину
        </button>
      )}
    </form>
  );
};
