import React from 'react';
import about_us from 'src/assets/img/main/about_us.webp';

import './index.css';

export default () => {
  return (
    <div id="aboutus" className="aboutus">
      <div className="about_us-image-wrapper">
        <img src={about_us} className="about_us__image" alt="about_us" />
      </div>
    </div>
  );
};
