import React from 'react';

import ProductPreview from 'src/common/components/ProductPreview';

import './index.css';

export default ({ category }) => {
  return (
    <div className="category">
      <h1 className="category__title">{category.title}</h1>
      <div className="category__products-list">
        {category.products.map(product => {
          return <ProductPreview {...{ product, category }} key={product.id} />;
        })}
      </div>
    </div>
  );
};
