import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import { HashLink as Link } from 'react-router-hash-link';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SizeSelector from 'src/common/components/SizeSelector';
import CalculateForm from 'src/support/components/CalculateForm';

import LeftArrowSVG from 'src/assets/icons/left_arrow.svg';
import RightArrowSVG from 'src/assets/icons/RightArrowSVG.svg';

import './index.css';

export default ({
  product,
  products,
  cart,
  selectedVolume,
  loadProduct,
  setVolume,
}) => {
  // const RightNav = React.memo(({ disabled, onClick }) => {

  //   return (
  //     <button
  //       type="button"
  //       className="image-gallery-icon image-gallery-right-nav"
  //       disabled={disabled}
  //       onClick={onClick}
  //       aria-label="Next Slide"
  //     >
  //       <img src={RightNavSVG} alt="rn" />
  //     </button>
  //   );
  // });

  // RightNav.displayName = 'RightNav';

  // RightNav.propTypes = {
  //   disabled: bool.isRequired,
  //   onClick: func.isRequired,
  // };
  // const renderRightNav = (onClick, disabled) => (
  //   <RightNav onClick={onClick} disabled={disabled} />
  // );
  const [totalWeight, setTotalWeight] = useState(0);
  const [carouselPhotos, setCarouselPhotos] = useState([]);
  const { category, id } = useParams();

  useEffect(() => {
    function handleTotalWeight(cart) {
      var weight = [];
      var totalWeight = 0;
      cart.forEach(product => {
        weight.push(product.weight);
      });
      totalWeight = weight.reduce((sum, current) => +sum + +current, 0);
      setTotalWeight(totalWeight);
    }

    handleTotalWeight(cart);
  }, [cart]);

  useEffect(() => {
    const selectedCategory = products.find(cat => cat.id === category);
    const prod = selectedCategory.products.find(prod => prod.id === id);
    loadProduct(prod);
    setVolume(prod.volumes[prod.volumes.length - 1]);

    const handleCarousel = () => {
      setCarouselPhotos([]);
      product.photos.map(photo => {
        return setCarouselPhotos(carouselPhotos => [
          ...carouselPhotos,
          {
            original: photo.link,
            thumbnail: photo.link,
          },
        ]);
      });
    };

    if (product) {
      handleCarousel();
    }
  }, [product, category, id]);

  if (!product) return null;
  return (
    <div className="product">
      <Helmet>
        <title>
          {product.category} - {product.title} - Динская Мельница!
        </title>
        <meta name="description" content={`${product.specification}`} />
      </Helmet>
      <Link to="/#production-list" className="product__back-link">
        {window.innerWidth < 600 && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <LeftArrowSVG className="product__arrow" />
            <h1 className="product__back-link__title">Все товары</h1>
          </div>
        )}
        {window.innerWidth > 600 && (
          <div
            style={{
              display: 'flex',
              width: '400px',
              alignItems: 'center',
            }}
          >
            <h1 className="product__back-link__title--d">{product.category}</h1>
            <RightArrowSVG className="product__arrow" />
            <h1
              style={{ color: 'black' }}
              className="product__back-link__title--d"
            >
              {product.title}
            </h1>
          </div>
        )}
      </Link>

      {totalWeight < 1000 && window.innerWidth < 600 ? (
        <>
          <span className="cart__warning">минимальный заказ 1000 кг</span>
        </>
      ) : (
        ''
      )}
      {totalWeight < 1000 && window.innerWidth > 600 && (
        <>
          <h1 className="cart__warning--big">минимальный заказ 1000 кг </h1>
        </>
      )}
      <div className="product__presentation">
        <div className="product__carousel">
          <ImageGallery
            //part of styles in the  index.css
            showBullets={window.screen.width > 600 ? true : false}
            thumbnailPosition="bottom"
            showFullscreenButton={window.screen.width > 600 ? true : false}
            useBrowserFullscreen={false}
            showNav={window.innerWidth > 600 ? true : false}
            items={carouselPhotos}
          />
        </div>
        <div className="product__main-info-block">
          <h1 className="product__category">
            {product.category.toUpperCase()}
          </h1>
          <h1 className="product__title">{product.title}</h1>
          <p className="product__specification">{product.specification}</p>
          <h1 className="product__suitable-block-title">Подходит для:</h1>
          <div className="product__suitable-block">
            {product.suitable.map(suitableProduct => {
              return (
                <span
                  key={suitableProduct}
                  className="product__suitable-product"
                >
                  {suitableProduct}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <h1 className="product__characteristics-block__title">Характеристики</h1>
      <div className="product__characteristics-block">
        <div className="product__characteristic">
          <h1 className="product__characteristic__title">Цена за 1шт:</h1>
          <span className="product__dotted-line" />
          <h1 className="product__characteristic__info">
            {selectedVolume.price * selectedVolume.volume}
            <span style={{ fontFamily: 'Inter' }}>&#8381;</span>
          </h1>
        </div>
        {product.characteristics.map(char => {
          return (
            <div key={char.id} className="product__characteristic">
              <h1 className="product__characteristic__title">{char.title} :</h1>
              <span className="product__dotted-line" />

              <h5 className="product__characteristic__info">
                {char.characteristic}
              </h5>
            </div>
          );
        })}
      </div>
      <h1 className="product__characteristics-block__title">
        Рассчитать стоимость
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: `${window.innerWidth > 600 ? 'row' : 'column'}`,
        }}
      >
        <SizeSelector />
        <div>
          <h1 className="product__price-title">Цена за кг:</h1>
          <div className="product__price-block">
            <h1 className="product__price">
              {selectedVolume.price} <span>&#8381;</span>/кг
            </h1>
            <h1 className="product__price-for-one">
              (Цена за 1шт {selectedVolume.price * selectedVolume.volume}
              <span style={{ fontFamily: 'Inter' }}>&#8381;</span>)
            </h1>
          </div>

          <h5 className="product__price-disclamer">
            Самая актуальная цена по телефону!
          </h5>
        </div>
      </div>
      <div style={{ marginBottom: '40px' }}>
        <CalculateForm link={`/product/${category}/${id}`} />
      </div>
    </div>
  );
};
