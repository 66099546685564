import { connect } from 'react-redux';

import Cart from './Cart';
import { deleteFromCart } from 'src/common/redux/actions';

export default connect(
  state => {
    return {
      product: state.common.product,
      cart: state.common.cart,
      lastUrl: state.common.lastUrl,
    };
  },
  { deleteFromCart },
)(Cart);
