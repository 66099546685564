import { connect } from 'react-redux';

import CartPopup from './CartPopup';
import { deleteFromCart } from 'src/common/redux/actions';

export default connect(
  state => {
    return {
      cart: state.common.cart,
    };
  },
  { deleteFromCart },
)(CartPopup);
