import React from 'react';

import Callus from 'src/assets/icons/callus.svg';
import Telegram from 'src/assets/icons/telegram.svg';
import Whatsapp from 'src/assets/icons/whatsapp.svg';
import Mail from 'src/assets/icons/mail.svg';
import Route from 'src/assets/icons/route.svg';
import RouteD from 'src/assets/icons/route--d.svg';

import './index.css';

export default () => {
  return (
    <div className="fast-contacts-block">
      {window.innerWidth > 600 && (
        <div className="fast-contacts-block__links-container--big">
          <a
            className="fast-contacts__link"
            href="https://t.me/Din_Mel"
            target="blank"
          >
            <Telegram className="fast-contacts__icon" />

            <h4 className="fast-contacts__link__title fast-contacts__link__title--telegram">
              Telegram
            </h4>
          </a>
          <a
            target="blank"
            className="fast-contacts__link"
            href="https://www.google.com/maps/place/%D0%94%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F+%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D0%B0/@45.054052,39.9330624,8.8z/data=!4m5!3m4!1s0x0:0x667e72f01d0496b6!8m2!3d45.2251052!4d39.1971512"
          >
            <RouteD className="fast-contacts__icon" />

            <h4
              style={{ color: '#3375E0' }}
              className="fast-contacts__link__title"
            >
              Проложить маршрут
            </h4>
          </a>
          <a
            target="blank"
            className="fast-contacts__link"
            href="whatsapp://send/?phone=+79385156161"
          >
            <Whatsapp className="fast-contacts__icon" />

            <h4 className="fast-contacts__link__title fast-contacts__link__title--whatsapp">
              WhatsApp
            </h4>
          </a>
          <a className="fast-contacts__link" href="tel:+79385156161">
            <Callus className="fast-contacts__icon" />

            <h4 className="fast-contacts__link__title fast-contacts__link__title--callus">
              Звонок
            </h4>
          </a>
          <a
            className="fast-contacts__link"
            href="mailto:din.melnica@gmail.com"
          >
            <Mail className="fast-contacts__icon" />

            <h4 className="fast-contacts__link__title fast-contacts__link__title--email">
              Email
            </h4>
          </a>
        </div>
      )}
      {window.innerWidth < 600 && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="fast-contacts-block__links-container--small">
            <a
              target="blank"
              className="fast-contacts__link"
              href="https://t.me/Din_Mel"
            >
              <Telegram className="fast-contacts__icon" />

              <h4 className="fast-contacts__link__title ">Telegram</h4>
            </a>

            <a
              target="blank"
              className="fast-contacts__link"
              href="whatsapp://send/?phone=+79385156161"
            >
              <Whatsapp className="fast-contacts__icon" />

              <h4 className="fast-contacts__link__title ">WhatsApp</h4>
            </a>
            <a className="fast-contacts__link" href="tel:+79385156161">
              <Callus className="fast-contacts__icon" />

              <h4 className="fast-contacts__link__title ">Позвонить</h4>
            </a>
            <a
              className="fast-contacts__link"
              href="mailto:din.melnica@gmail.com"
            >
              <Mail className="fast-contacts__icon" />

              <h4 className="fast-contacts__link__title "> Email</h4>
            </a>
          </div>
          <a
            target="blank"
            href="https://www.google.com/maps/place/%D0%94%D0%B8%D0%BD%D1%81%D0%BA%D0%B0%D1%8F+%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D0%B0/@45.054052,39.9330624,8.8z/data=!4m5!3m4!1s0x0:0x667e72f01d0496b6!8m2!3d45.2251052!4d39.1971512"
            className="google-map__route-button"
          >
            <Route
              style={{
                marginRight: '-15px',
              }}
              className="fast-contacts__icon"
            />

            <h3 className="route-button__title">Проложить маршрут</h3>
          </a>
        </div>
      )}
    </div>
  );
};
