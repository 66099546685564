import { combineReducers } from 'redux';

import support from 'src/support/redux/reducer';
import common from 'src/common/redux/reducer';

const rootReducer = combineReducers({
  common,
  support,
});

// const rootReducer = (state, action) => {
//   if (action.type === 'SENDING_FAILED') {
//     // storage.removeItem('persist:root');

//     return (
//       console.log(state.common.cart),
//       (state.common.cart = []),
//       console.log(state.common.cart),
//       appReducer(undefined, action)
//     );
//   }
//   return appReducer(state, action);
// };

export default rootReducer;
