import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { HashLink as Link } from 'react-router-hash-link';

import './index.css';
import Cart from 'src/assets/icons/cart.svg';
import landing1 from 'src/assets/img/main/landing1.webp';
import Contacts from 'src/support/components/Contacts';
import { HomeOutlined } from '@ant-design/icons';
import FastContactBlock from 'src/support/components/FastContactBlock';

export default ({ children, cartIndicator }) => {
  const location = useLocation();

  return (
    <div
      id={`${location.pathname === '/' ? 'main' : 'product'}`}
      className={`layout ${
        location.pathname === '/' ? '' : 'layout--without_back'
      } `}
    >
      <img
        src={landing1}
        alt="lalanding"
        className={`layout ${
          location.pathname === '/'
            ? 'layout__background-image'
            : 'layout__background-image--hidden'
        } `}
      />
      <Helmet>
        <title>Мука пшеничная в Краснодарском крае! - Динская Мельница!</title>
        <meta
          name="description"
          content="Оптовые поставки пшеничной муки!Собственное производство!21 год на рынке!Подберём муку под ваше производство!Три сорта хлебопекарной муки!"
        />
      </Helmet>

      <div
        className={` ${
          location.pathname === '/cart' && window.innerWidth < 600
            ? 'hide'
            : 'layout__header'
        } `}
      >
        <Link to="/#main" className="homebutton-container">
          <HomeOutlined className="homebutton" />
        </Link>

        <div className="layout__links-container">
          <Link to="/#production-list" className="layout__link">
            <h3 className="layout__link__title">Продукция</h3>
          </Link>
          <Link to="/#aboutus" className="layout__link">
            <h3 className="layout__link__title">О нас</h3>
          </Link>
          <Link to="/#contacts" className="layout__link">
            <h3 className="layout__link__title">Контаткы</h3>
          </Link>
        </div>
        <Link to="/cart#cart" className="layout__link--cart">
          <Cart className="layout__cart-icon" />
          {cartIndicator > 0 && (
            <div>
              <p className="layout__cart-indicator">{cartIndicator}</p>
            </div>
          )}
        </Link>
      </div>
      {children}
      {(location.pathname !== '/cart' || window.innerWidth < 600) && (
        <div id="contacts">
          <Contacts />

          <FastContactBlock />
        </div>
      )}
      <div className="layout__header">
        <Link to="/#main" className="homebutton-container">
          <HomeOutlined className="homebutton" />
        </Link>

        <div className="layout__links-container">
          <Link to="/#production-list" className="layout__link">
            <h3 className="layout__link__title">Продукция</h3>
          </Link>
          <Link to="/#aboutus" className="layout__link">
            <h3 className="layout__link__title">О нас</h3>
          </Link>
          <Link to="/#contacts" className="layout__link">
            <h3 className="layout__link__title">Контаткы</h3>
          </Link>
        </div>
        <Link to="/cart#cart" className="layout__link--cart">
          <Cart className="layout__cart-icon" />
          {cartIndicator > 0 && (
            <div>
              <p className="layout__cart-indicator">{cartIndicator}</p>
            </div>
          )}
        </Link>
      </div>
    </div>
  );
};
