import { connect } from 'react-redux';

import Product from './Product';
import { loadProduct, setVolume } from 'src/common/redux/actions';

export default connect(
  state => {
    return {
      product: state.common.product,
      products: state.common.products,
      selectedVolume: state.common.selectedVolume,
      cart: state.common.cart,
    };
  },
  { loadProduct, setVolume },
)(Product);
