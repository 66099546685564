import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import './index.css';

export default ({ product, category, loadProduct, setVolume }) => {
  return (
    <div
      className="product-preview"
      onClick={() => {
        loadProduct(product);
        setVolume(product.volumes[product.volumes.length - 1]);
      }}
    >
      <Link to={`/product/${category.id}/${product.id}#product`}>
        <img
          className="product-preview__icon"
          src={product.thumbnail}
          alt="icon"
        />

        <div className="product-preview__info-block">
          <div className="product-preview__title-block">
            {window.innerWidth > 600 && (
              <div className="product-preview__short-category">
                <h6 style={{ fontWeight: '500' }}>{product.shortCategory}</h6>
              </div>
            )}
            <h5 className="product-preview__title">{product.title}</h5>
          </div>

          <div className="product-preview__price-block">
            <div className="product-preview__price">
              {product.volumes.length > 1 ? (
                <div style={{ display: 'flex' }}>
                  <h3 style={{ fontSize: '16px' }}> от </h3>
                  &nbsp;
                </div>
              ) : (
                ''
              )}
              <h3>
                {product.volumes[product.volumes.length - 1].price}
                &nbsp;
              </h3>
              <h3>
                <span style={{ fontFamily: 'Inter' }}>&#8381;</span>
              </h3>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
